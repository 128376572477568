import { render, staticRenderFns } from "./IncreaseNumber.vue?vue&type=template&id=29e4c2fd&"
import script from "./IncreaseNumber.vue?vue&type=script&lang=js&"
export * from "./IncreaseNumber.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../var/task/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/task/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('29e4c2fd')) {
      api.createRecord('29e4c2fd', component.options)
    } else {
      api.reload('29e4c2fd', component.options)
    }
    module.hot.accept("./IncreaseNumber.vue?vue&type=template&id=29e4c2fd&", function () {
      api.rerender('29e4c2fd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "tmp/tmp-8iWFAriuSQmxY/JS/comp/IncreaseNumber.vue"
export default component.exports